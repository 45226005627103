



























































































import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {Prop} from "vue-property-decorator";
import LogoUploader from "@/components/SygniLogoUploader.vue";
import { CreateFundManagerDTO } from "@/modules/genprox/modules/fund/store/types";
import { required } from "vuelidate/lib/validators";
import { createAddressValidation } from "@/modules/shared/globalValidations";
import {mapGetters, mapState} from "vuex";
import {GlobalSelects, LegaLEntitySelects} from "@/modules/shared/dictionary-store/types";
import Utils from "@/modules/shared/utils/utils";
import {REDIRECTED_PATH} from "@/modules/genprox/genprox.routing";
import {UploadFileDTO} from "@/modules/genprox/models/Shared";
import AddNewFundBoilerplate from './AddNewFundBoilerplate.vue';

Component.registerHooks(['validations'])
@Component({
  components: {
    LogoUploader,
    SygniInputError,
    SygniDatePicker,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniLoader, SygniSelect, SygniInput, SygniContainerTitle },
  computed: {
    ...mapState('dictionary', {
      legalEntitySelects: (state: any) => state.legalEntitySelects,
    }),
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects'
    })
  }
})
export default class AddNewFundManager extends AddNewFundBoilerplate {

  @Prop({ default: 'fund'}) fundType: string; // fund / fund-manager ?
  legalEntitySelects!: LegaLEntitySelects;
  globalSelects!: GlobalSelects;

  newFund: CreateFundManagerDTO = { registrationAddress: {}, employee: { managementTeam: false} } as CreateFundManagerDTO;
  logo: File = null;
  isLoading: boolean = false;
  @Prop({default: ''}) useType!: 'KNF_REPORTING_PACKAGE';

  get routerLink() {
    return this.$route.query?.id ? `/fund/choose-fund-type?id=${this.$route.query.id}` : `/fund/choose-fund-type`;
  }

  async addFund(): Promise<void> {
    this.isLoading = true;

    this.$v.$touch();
    if(!this.$v.$error){
      try {
        const fundId: string = await this.$store.dispatch('AIFM/createFundManager', this.newFund);
        if(this.logo){
          const payload: UploadFileDTO = {
            config: Utils.getUploadFileConfig(),
            file: this.logo
          }
          await this.$store.dispatch('genprox/putLegalEntityLogo', {legalEntityId: fundId, ...payload});
        }
        this.$notify({
          type: 'success',
          title: 'Fund manager has been successfully created!',
          text: 'You will be redirected to fund manager in few seconds.',
          duration: 1500,
        });
        setTimeout(async () => {
          await this.executeActionsAfterSuccessfulFundAdd(fundId)
        }, 1500)
      } catch (e) {
        e;
      }
    }
    this.isLoading = false;
  }
  async executeActionsAfterSuccessfulFundAdd( newFundId: string ): Promise<void> {
    switch (this.useType) {
      case "KNF_REPORTING_PACKAGE":
        if (this.$route.query?.id) {
          localStorage.setItem( REDIRECTED_PATH, `fund/accept-package/${this.$route.query.id}` );
        }
        this.$root.$emit('setUserContext', { id: newFundId })
        break
      default:
        await this.$router.push({name: 'welcome'});
    }
  }

  validations (){
    return {
      newFund: {
        name: {required},
        registrationAddress: createAddressValidation(),
        legalForm: { required },
        endOfFinancialPeriod: { required },
        employee: { position: { required }}
      }
    }
  }


}
